/*Cretae Credentials*/
@import "assets/styles/app.css";

.user-edit-container {
  /* background: #0ff; */

  align-self: center;
  justify-self: center;
  position: absolute;

  display: flex;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.user-edit-container .card{
  margin-bottom: 20px;
}

.user-edit-body {
  display: flex;
  flex-direction: column;
  width: 900px !important;
}

.user-edit-body .card-body{
  height: auto !important;
  padding: 25px !important;
}

#user-edit-button {
  background: #fff;
}
