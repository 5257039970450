/*credentials*/
@import "assets/styles/app.css";

.credentials-container {
  display: flex;
  flex-direction: column;
  .card-title {
    justify-content: space-between;
    align-items: center;
  }
  .no-credentials {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    text-transform: none;
  }
  .return-button {
    color: var(--COLOR_1);
    &:hover {
      color: var(--COLOR_1_tr);
    }
  }
  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: var(--COLOR_0);
    background: var(--COLOR_1);
    font-weight: 500;
    &:hover {
      color: var(--COLOR_0);
      /* background: var(--COLOR_1_tr); */
      transform: translateY(-1px);
      box-shadow: var(--sh-1);
      /* font-weight: 600; */
    }
    &:active {
      background: var(--COLOR_1);
      transform: translateY(1px);
      color: #fff;
    }
  }
  td .btn-group {
    position: static;
  }
}
.nullable-table {
  position: absolute;
  inset: 0;
}
