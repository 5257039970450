.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow-y: hidden;
}

.btn-new-event {
    width: fit-content;
    margin: 20px 0;
}

.container .card {
    height: 85% !important;
}

.container .card-body {
    overflow-y: auto !important;
    padding:0 25px  !important;
    
}

.container .table-responsive {
    overflow-y: scroll !important;
    padding-right: 30px !important;
    padding-left: 10px !important;
}

.container thead {
    position: sticky;
    width: 100%;
    top: 0;
}

.container tbody tr {
    vertical-align: middle;
}



.nav-item {
    border: none;
    border-bottom: none;
    padding: 15px;
    cursor: pointer;
    margin-top: 20px;
}

.active-tab {
    border: 1px solid #dee2e6;
    border-bottom: none;
    background-color: #f0f8ff;
}

.tab-content {
    border: 1px solid #dee2e6;
    border-top: none;
}

.event-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr;
    padding: 10px 5px;
    border-bottom: 1px solid #dee2e6;
    align-items: center;
}

.event-description {
    font-size: 10px;
    display: flex;
    width: 100%;
    align-items: end;
    margin-left: 15px;
}

.event-container span  {
    width: 80%;
}

.event-container button {
    margin-bottom: 5px;
    margin-left: 5px;

}
