:root {
  --COLOR_BG: #3f3a3f3f;

  --COLOR_0: #ffffff;
  --COLOR_1: #d0661c;
  --COLOR_2: #eeeefd;
  --COLOR_3: #333;
  --COLOR_4: #9717;

  --COLOR_1_tr: #d0661c77;
  --COLOR_2_tr: #eeeefd77;
  --COLOR_3_tr: #33333377;
  /* ****************************************************************************** */
  --sh-1: 0 0 7px 3px #3335;
  --sh-1-inv: 0 0 10px 3px #f777;

  --sh-2: 0px 0px 7px 3px #7775;

  --br-xs: 0;
  --br-sm: 576px;
  --br-md: 768px;
  --br-lg: 992px;
  --br-xl: 1200px;

  /* sizes */
  --bg-top: 40vh;
  --card-w: 300px;
  --card-h: 500px;
  /* icon size : 9.5/2 */
  --logo-h: 21px;
  --logo-w: calc(4.25 * var(--logo-h));

  --input-w: 12rem;

  --inf: calc(infinity * 1px);

  --text: white;
  --text-destaque: var(--COLOR_1);
}
