@import "./colors.css";

::-webkit-scrollbar-corner {
  background: var(--COLOR_3_tr);
}

::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
}

::-webkit-scrollbar-track {
  background: var(--COLOR_3_tr);
}

::-webkit-scrollbar-thumb {
  background: var(--COLOR_1_tr);
  border: 3px var(--COLOR_4) solid;
  border-radius: calc(infinity * 1px);
  background-image: linear-gradient(var(--COLOR_1_tr), var(--COLOR_1));
}

::-webkit-scrollbar-thumb:hover {
  background: var(--COLOR_1);
  border: 0;
  cursor: pointer;
}

::-webkit-scrollbar-track-piece {
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  list-style: none;
  font-size: 1rem;
  text-decoration: none;
  font-family: "Helvetica";
}

.header-container {
  background: var(--COLOR_1);
  color: var(--COLOR_0);
  box-shadow: var(--sh-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  padding: 1rem 1.5rem;

  & .left {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    gap: 1rem;

    * {
      display: flex;
      font-size: 1rem;
    }
    & h1 {
      height: 25px;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }
}
.return-button {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #fffc;
  &:hover {
    color: #ffffff;
    transform: translateX(1px);
  }
  &:active {
    color: #fffc;
    transform: translateX(-1px);
  }
}
.logo {
  height: var(--logo-h);
  width: var(--logo-w);
}

.page-container {
  position: absolute;
  display: flex;
  flex-direction: row;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: var(--COLOR_BG);
  overflow: hidden;
}

.side-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  z-index: 30;
  box-shadow: var(--sh-2) !important;
  font-size: 1rem;
  gap: 1rem;
  width: calc(0.5 * var(--br-sm)) !important;
  background: var(--COLOR_0) !important;
}
.nav-links-container {
  overflow-x: hidden;
  overflow-y: overlay;
  padding-top: 1.5rem;
  padding-left: 0;
  width: calc(100% + 2rem);
  & .nav-links {
    text-transform: capitalize;
    width: 100%;
    display: flex;
    flex: 1;
    width: 100%;
    padding: 1rem;
    font-size: 1.1rem;
    color: var(--COLOR_3);
    cursor: pointer;
    border: none;
    background: #b0b0;
    &:hover {
      color: var(--COLOR_1);
      background: var(--COLOR_1_tr);
    }
  }
  & .isActive {
    border-bottom: var(--COLOR_1) solid 2px;
    color: var(--COLOR_1);
    background: var(--COLOR_3);
    font-weight: 800;
    &:hover {
      color: var(--COLOR_1);
      background: var(--COLOR_3);
      font-weight: 900;
    }
  }
}
.main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: overlay;
  overflow-y: hidden;
  position: relative;
}

.cards-container {
  top: 25vh;
  left: 1rem;
  right: 1rem;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  & .card {
    box-shadow: var(--sh-1);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 1rem;
    height: var(--card-h);
    width: var(--card-w);
    color: black;
    background: var(--COLOR_0);
  }
}

select:active,
select:hover,
select {
  border-radius: 1px;
  cursor: pointer;
}
select::after {
  border-radius: 1px !important;
}

.home-container,
.not-found-container,
.events-container,
.logs-container,
.integrations-container,
.credentials-container {
  margin: auto;
  background: var(--COLOR_0);
  border-radius: 0.5rem;
  box-shadow: var(--sh-1);
  text-transform: capitalize;

  position: relative;

  min-width: 75%;
  min-height: 50%;

  max-width: 90%;
  max-height: 90%;

  align-self: center;
  justify-self: center;

  padding: 1rem;
  overflow: hidden;

  & .card-title {
    display: flex;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
  }
  .nullable-table {
    display: flex;
    flex: 1;

    font-style: italic;

    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;

    font-size: 1rem;
  }

  & .table-container {
    display: flex;
    flex-direction: column;
    overflow: overlay !important;
    min-height: 50%;
    max-height: 50vh;
    cursor: default;

    margin-bottom: 3rem;

    & table {
      width: 100%;
      max-height: 75vh;
      border-spacing: 0;
      margin-bottom: 3rem;
    }

    .table-head {
      background: var(--COLOR_0);
      z-index: 1;
      position: sticky;
      top: 0;
      text-transform: uppercase;
    }
    & tbody {
      margin-bottom: 5rem !important;
    }
    & tr {
      &:hover {
        background: #ff03;
      }
    }
    & th {
      color: var(--COLOR_1);
      border-bottom: #3333 3px solid;
    }
    & td {
      color: black;
      border-top: #3333 2px solid;
    }
    & td,
    th {
      padding: 0.5rem;
      font-size: 0.75rem;
      text-align: left;
      text-wrap: nowrap;
      word-wrap: nowrap;
      /* & button:not(.dropdown*) */
      & button:not(.dropdown-item) {
        cursor: pointer;
        color: var(--COLOR_0);
        background: var(--COLOR_1);
        padding: 5px;
        width: 100px;
        border: none;
        border-radius: var(--inf);
        border-radius: 5px;
        align-items: center;
        text-justify: center;
        font-weight: bolder;
        &:hover {
          box-shadow: var(--sh-1);
          transform: translateY(-1px);
        }
        &:active {
          transform: translateY(1px);
        }
      }
    }
    & button:disabled {
      background: #ccc;
      color: #7777;
      cursor: default;
      &:hover {
        box-shadow: none;
      }
    }
    .main-col {
      width: 100%;
    }
  }

  & .add-button {
    display: flex;

    align-items: center;
    justify-content: center;
    align-self: center;

    background: var(--COLOR_1);
    color: var(--COLOR_0);

    height: 2rem;

    font-weight: 500;
    font-size: 0.75rem;
    border-radius: 5px;
    &:hover {
      transform: translateY(1px);
      box-shadow: var(--sh-1);
    }
    &:active {
      transform: translateY(-1px);
    }
  }

  .return-button {
    color: var(--COLOR_1);
    width: 25px;
    height: 25px;
    &:hover {
      color: var(--COLOR_1_tr);
    }
  }
  & button:disabled {
    background: #ccc;
    color: #7777;
    cursor: default;
    font-weight: normal;
    &:active {
      background: var(--COLOR_1);
      transform: none;
      color: #fff;
    }
    &:hover {
      color: var(--COLOR_0);
      transform: none;
      box-shadow: var(--sh-1);
      color: #7777;
      cursor: default !important;
      box-shadow: none;
      transform: none;
    }
  }
  & .card-footer {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;

    background: var(--COLOR_0);
    padding: 2rem;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
/* mudar para 404/style.css */
.not-found-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  * {
    font-size: 1rem;
  }
  & .card-title {
    display: flex;
    width: 100%;
    font-weight: bolder;
    justify-content: space-between;
    align-items: center;
    & h2 {
      font-weight: bold;
      align-items: center;
      margin: auto 0;
    }
  }
  & .return-button {
    display: flex;
    width: 25px;
    height: 25px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: var(--COLOR_1);
    &:hover {
      color: var(--COLOR_1_tr);
      transform: translateX(1px);
    }
    &:active {
      color: var(--COLOR_1_tr);
      transform: translateX(-1px);
    }
  }
  & .go-home-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    align-self: center;
    font-size: 1rem;
    padding: 0.1rem 1rem;
    background: var(--COLOR_1);
    color: var(--COLOR_0);
    border-radius: var(--inf);
  }
}
/* mudar para Events/style.css */
.events-container {
  width: 100%;
  height: 100%;
  z-index: 50;
  position: relative;
  padding: 2rem;
  box-shadow: var(--sw-2);
  & .events-card-container {
    display: flex;
    flex-direction: column;
    background: var(--COLOR_0);
    width: 100%;
    height: 100%;
    padding: 2rem;
    & .events-title {
      font-weight: 400;
      text-transform: capitalize;
      margin: 0 0 1rem 0;
      & * {
        font-size: 1.3rem;
        & b {
          font-weight: 600;
        }
      }
    }
    & .events-card {
      border-radius: 10px;
      border: 2px solid #3339;
      overflow: hidden;
      & .events-navbar {
        display: flex;
        user-select: none;
        border-radius: 0px 0px 0 0;
        background: #3339;

        & .event-type {
          display: flex;
          border-radius: 0;
          cursor: pointer;
          width: 9rem;
          padding: 10px 0 7px 0;
          align-items: center;
          justify-content: center;
          color: #3335;
          font-weight: 300;
          font-size: 0.9rem;
        }
        & .is-active {
          color: #000;
          background: #fff;
          font-weight: 600;
          border-radius: 10px 10px 0 0;
          border-bottom: 0;
        }
      }
      & .event-content {
        display: flex;
        padding: 1rem;
        flex-wrap: wrap;
        overflow: overlay;
        flex: 1;
      }
    }
  }
}

.show-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  & input {
    width: 100%;
  }
  & #show-button {
    position: absolute;
    right: 2rem;
    cursor: pointer;
    border-radius: var(--inf);
    &:hover {
      box-shadow: inset var(--sh-1);
      transform: translateY(1px);
    }
    &:active {
      transform: translateY(-1px);
    }
  }
}

.card-container {
  background: none;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 2%;
}
