.card-container {
    overflow-y: hidden !important;
    align-items: center;
}

.details-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    grid-column-gap: 1%;
}

.details-container .card {
    width: 100% !important;
    overflow-y: auto !important;
}

.table-responsive{
    overflow-y: hidden !important;
}

.list-group-item  {
    display: grid !important;
    gap: 2% !important;
    grid-template-columns: repeat(auto-fit, minmax(21%, auto)) !important;
    padding-top: 18px !important;
}

.shipment-data {
    display: flex;
    flex-direction: column;
}

.shipment-data b, p {
    font-size: 12px;
}

.history-container {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin: 10px 0;
    padding: 18px;
}

.history-container p {
    padding: 0;
    margin: 0;
}

.event-container p, b {
    margin: 0;
    padding: 0;
    font-size: 13px;
}