@import "assets/styles/app.css";

.card-container,
.add-container {
  background: var(--COLOR_0);
  position: relative;
}

.delete-icon {
  width: 2rem;
  height: 2rem;
  padding: 5px;
  border-radius: 5px;
  background: var(--COLOR_0);
  margin: 2px;
  cursor: pointer;
  &:hover {
    color: #a00;
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
}

.edit-container,
.add-container {
  margin: 2rem;
  display: flex;
  padding: 2rem;
  border-radius: 1rem;
  background: var(--COLOR_0);
  display: flex;
  flex-direction: column;
  box-shadow: var(--sh-1);
  & button {
    background: var(--COLOR_1);
    border-radius: var(--inf);
    padding: 0.33rem;
    color: var(--COLOR_0);
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 1px 1px #3333;
    }
    &:active {
    }
    & .Not-Active {
      cursor: none;
      background: #3333;
      color: #3337;
    }
  }
}
.el {
  color: #333;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  & input {
    padding: 0 0.5rem;
  }
}

.edit-btn,
.delete-btn,
.add-btn {
  cursor: pointer;
  padding: 0.5rem;
  &:hover {
    color: #3337;
    box-shadow: var(--sh-2);
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
}
.delete-btn {
  color: red;
  background: #3330;
}
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #333;
  width: 2rem;
  height: 2rem;
  background: #3333;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  border-radius: var(--inf);
  cursor: pointer;
  &:hover {
    box-shadow: var(--sh-1);
    color: white;
  }
}
