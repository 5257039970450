.customer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 5%;
}
.customer-container .card {
  height: 100%;
}

.customer-container .card-body {
  height: fit-content !important;
}

.customer-search {
  background-color: white;
  border: 1px solid #dee2e6;
  display: flex;
  gap: 50px;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  height: fit-content !important;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px !important;
}
.services-container {
  padding: 20px;
}

.services-container li {
  display: flex;
  gap: 15px;
}

.services-container label,
input {
  cursor: pointer;
}

.services-container p {
  margin: 0;
  margin-top: 3px;
}
