.card-body {
    height: 90vh !important;
}

.table-responsive {
    height: 100% !important;
}

.table .thead  {
    top: 0 !important;
    left: 0 !important;
    z-index: 2 !important;
    position: sticky !important;
}

.card-container .card {
    width: 95%;
}

.card-container  table *{
    vertical-align: middle !important;
    font-size: 13px;
}