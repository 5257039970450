/*Cretae Credentials*/
@import "assets/styles/app.css";

.credential-create-container {
  /* background: #0ff; */

  align-self: center;
  justify-self: center;
  position: absolute;

  display: flex;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.credential-create-body {
  display: flex;
  flex-direction: column;
  width: 900px !important;
  height: 350px;
}

#credential-save-button {
  background: #fff;
}
