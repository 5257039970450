/* .login-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.login-container img {
  max-width: 350px;
  margin: auto;
  width: 70vw;
  height: auto;
}

.login-container .card {
  width: 80%;
  max-width: 580px;
}

.login-container .card-body {
  height: fit-content !important;
} */
input {
  cursor: text;
}
